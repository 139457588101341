import React from 'react';
import registerComponents from '../../../../maharo';
import HeaderNotice from '../components/header-notice/index.jsx';
// import HeadOverToDashboard from '../components/head-over-priv-dashboard/index.jsx';
import TOSUpdate from '../components/tos-update/index.jsx';
import Footer from '../components/footer';
import SecondaryFooter from '../components/second-footer';
import MarkdownHelp from '../components/markdown-help';
import JobsHomepage from '../components/jobs-homepage';
import PrivateStackshareCtaHomepage from '../components/private-stackshare-cta';
import AlternativesHomepage from '../components/alternatives-homepage';
import {SigninContent} from '../../../shared/library/modals/signin';
import SigninGlobalModal from '../components/signin/global';
import {withMobile} from '../../../shared/enhancers/mobile-enhancer';
import Advert from '../../../shared/library/advert/index.jsx';
import {withApolloContext} from '../../../shared/enhancers/graphql-enhancer';
import {NON_ADBLOCKED_AD_NAME} from '../../../shared/constants/settings';
import {privateMode, user} from '../../../data/shared/queries';
import {compose} from 'react-apollo';
import NavbarMenu from '../components/navbar/index.jsx';
import {withCurrentUser} from '../../../shared/enhancers/current-user-enhancer';
import {withPrivateMode} from '../../../shared/enhancers/private-mode-enchancer';
import resolvers from '../../../data/site/resolvers';
import StackFileCarousel from '../components/stack-file-carousel';

const client = algoliasearch(process.env.ALGOLIA_ID, process.env.ALGOLIA_CLIENT_SEARCH_API_KEY);
client.clearCache();
const searchIndex = client.initIndex(process.env.ALGOLIA_SEARCH_INDEX);

registerComponents(
  {
    Footer: Footer,
    SecondaryFooter,
    AlternativesHomepage,
    JobsHomepage,
    PrivateStackshareCtaHomepage,
    HeaderNotice,
    StackFileCarousel: compose(
      withApolloContext,
      withCurrentUser(user)
    )(props => <StackFileCarousel {...props} removeFirstStep={false} />),
    NavbarMenu: compose(
      withApolloContext,
      withCurrentUser(user),
      withPrivateMode(privateMode)
    )(NavbarMenu),
    // HeadOverToDashboard,
    TOSUpdate: withApolloContext(TOSUpdate),
    MarkdownHelp,
    SigninGlobalModal: compose(
      withMobile(false),
      withApolloContext
    )(SigninGlobalModal),
    SigninContent: withApolloContext(SigninContent),
    [NON_ADBLOCKED_AD_NAME]: withApolloContext(Advert)
  },
  resolvers(searchIndex)
);
